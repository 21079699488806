import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import { DatadogLoggingService } from '@edx/frontend-logging';

// List of all regexes for ignoring errors
const ignoredRegexArray = [
  '^Axios Error.*Possible local connectivity issue.',
];

// Join all ignored regexes with a '|'
const IGNORED_ERROR_REGEX = ignoredRegexArray.join('|');

const config = {
  loggingService: DatadogLoggingService,
  IGNORED_ERROR_REGEX,
  pluginSlots: {
    footer_plugin_slot: {
      keepDefault: true,
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'hidden_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: () => (
              <div className="empty-plugin" />
            ),
          },
        },
      ],
    },
  },
};

export default config;